import dashboard from './dashboard';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard]
};

export default menuItems;
