// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Пользователи',
            type: 'item',
            url: '/users',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'admins',
            title: 'Aдмин-персонал',
            type: 'item',
            url: '/admins',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'services',
            title: 'Услуги',
            type: 'item',
            url: '/services',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'restaurant',
            title: 'Pесторанные зоны',
            type: 'item',
            url: '/restaurant-area',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'carousel',
            title: 'Карусель',
            type: 'item',
            url: '/carousel',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'news',
            title: 'Новости и акции',
            type: 'item',
            url: '/news',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'contacts',
            title: 'Контакты',
            type: 'item',
            url: '/contacts',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'foods',
            title: 'Блюда',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'category',
                    title: 'Категории',
                    type: 'item',
                    url: '/category',
                    breadcrumbs: true
                },
                {
                    id: 'blyuda',
                    title: 'Блюда',
                    type: 'item',
                    url: '/blyuda',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'roomType',
            title: 'Тип номера',
            type: 'item',
            url: '/roomType',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'roomFeatures',
            title: 'Oсобенность комнаты',
            type: 'item',
            url: '/roomFeatures',
            icon: icons.IconShadow,
            breadcrumbs: true
        },
        {
            id: 'policy',
            title: 'Политика Конф',
            type: 'item',
            url: '/policy',
            icon: icons.IconShadow,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
