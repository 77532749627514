/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const location = useLocation();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(async () => {
        const token = cookies.get('access_token');

        if (!token) {
            await navigate('/pages/login/login3');
        }
       
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
