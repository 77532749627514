/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable prettier/prettier */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing


const BasicTable = Loadable(lazy(() => import('views/MyComponents/UserTable/index')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const FormAdmin = Loadable(lazy(() => import('views/MyComponents/AdminAdd')));
const AdminsView = Loadable(lazy(() => import('views/MyComponents/AdminView')));


const ServicesTable = Loadable(lazy(() => import('views/MyComponents/ServicesTable/index')));

const ServicesView = Loadable(lazy(() => import('views/MyComponents/ServicesTable/ServiceView')));
const ServicesAdd = Loadable(lazy(() => import('views/MyComponents/ServicesTable/ServiceAdd')));

const Carousel = Loadable(lazy(() => import('views/MyComponents/Carousel/index')));
const CarouselAdd = Loadable(lazy(() => import('views/MyComponents/Carousel/CarouselAdd')));
const CarouselEdit = Loadable(lazy(() => import('views/MyComponents/Carousel/CarouselEdit')));
const PrivacyGet = Loadable(lazy(() => import('views/MyComponents/Policy')));


const News = Loadable(lazy(() => import('views/MyComponents/News/index.js')));

const NewsAdd = Loadable(lazy(() => import('views/MyComponents/News/NewsAdd')));
const NewsEdit = Loadable(lazy(() => import('views/MyComponents/News/NewsEdit')));


const Users = Loadable(lazy(() => import('views/MyComponents/Users')));
const UserAdd = Loadable(lazy(() => import('views/MyComponents/Users/UserAdd')));

const UserView = Loadable(lazy(() => import('views/MyComponents/Users/UserView')));

const Contacts = Loadable(lazy(() => import('views/MyComponents/Contacts/index')));

const Category = Loadable(lazy(() => import('views/MyComponents/CategoryFoods')));

const CategoryCart = Loadable(lazy(() => import('views/MyComponents/CategoryFoods/CategoryCart')));
const CategoryAdd = Loadable(lazy(() => import('views/MyComponents/CategoryFoods/CategoryAdd')));

const ListFood = Loadable(lazy(() => import('views/MyComponents/CategoryFoods/ListFood')));

const ListFoodAdd = Loadable(lazy(() => import('views/MyComponents/CategoryFoods/ListFoodAdd')));

const ListFoodCart = Loadable(lazy(() => import('views/MyComponents/CategoryFoods/ListFoodCart')));


const AreaRestaran = Loadable(lazy(() => import('views/MyComponents/AreaRestaran')));

const AreaAdd = Loadable(lazy(() => import('views/MyComponents/AreaRestaran/AreaAdd')));

const AreaEdit = Loadable(lazy(() => import('views/MyComponents/AreaRestaran/AreaEdit')));

const RoomType = Loadable(lazy(() => import('views/MyComponents/RoomType/index')));

const RoomAdd = Loadable(lazy(() => import('views/MyComponents/RoomType/RoomAdd')));


const RoomEdit = Loadable(lazy(() => import('views/MyComponents/RoomType/RoomEdit')));

const RoomFeatures = Loadable(lazy(() => import('views/MyComponents/RoomFeatures/index')));

const FeatureAdd = Loadable(lazy(() => import('views/MyComponents/RoomFeatures/FeatureAdd')));

const FeaturesEdit = Loadable(lazy(() => import('views/MyComponents/RoomFeatures/FeaturesEdit')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/users',
            element: <Users/>
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/admins',
            element: <BasicTable/>
        },
        {
            path: '/admins/add',
            element: <FormAdmin/>
        },
        {
            path: '/admins/:id',
            element: <AdminsView/>
        },
        {
            path: '/services',
            element: <ServicesTable/>
        },
        {
            path: '/services/:id',
            element: <ServicesView/>
        },
        {
            path: '/services/add',
            element: <ServicesAdd/>
        },
        {
            path: '/carousel',
            element: <Carousel/>
        },
        {
            path: '/carousel/add',
            element: <CarouselAdd/>
        },
        {
            path: '/carousel/edit/:id',
            element: <CarouselEdit/>
        },

        {
            path: '/restaurant-area',
            element: <AreaRestaran/>
        },
        
        {
            path: '/restaurant-area/add',
            element: <AreaAdd/>
        },
                
        {
            path: '/restaurant-area/:id',
            element: <AreaEdit/>
        },

        {
            path: '/news',
            element: <News/>
        },
        {
            path: '/news/add',
            element: <NewsAdd/>
        },
        {
            path: '/news/:id',
            element: <NewsEdit/>
        },
     
        {
            path: '/users/add',
            element: <UserAdd/>
        },
        {
            path: '/users/:id',
            element: <UserView/>
        },
        {
            path: '/contacts',
            element: <Contacts/>
        },
        {
            path: '/category',
            element: <Category/>
        },
        {
            path: '/category/add',
            element: <CategoryAdd/>
        },
        {
            path: '/category/:id',
            element: <CategoryCart/>
        },
        {
            path: '/blyuda',
            element: <ListFood/>
        },
        {
            path: '/blyuda/add',
            element: <ListFoodAdd/>
        },
        {
            path: '/blyuda/:id',
            element: <ListFoodCart/>
        },
        {
            path: '/blyuda/:id',
            element: <ListFoodCart/>
        },

        {
            path: '/roomType',
            element: <RoomType/>
        },

        {
            path: '/roomType/add',
            element: <RoomAdd/>
        },
        {
            path: '/roomType/:id',
            element: <RoomEdit/>
        },
        {
            path: '/roomFeatures',
            element: <RoomFeatures/>
        },
        {
            path: '/policy',
            element: <PrivacyGet/>
        },
        {
            path: '/roomFeatures/:id',
            element: <FeaturesEdit/>
        },


        {
            path: '/roomFeatures/add',
            element: <FeatureAdd/>
        }
      
       
    ]
};

export default MainRoutes;
