/* eslint-disable dot-notation */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable import/no-unresolved */
/* eslint-disable radix */
/* eslint-disable arrow-body-style */
/* eslint-disable no-else-return */
/* eslint-disable prettier/prettier */

import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router';
import Cookies from "universal-cookie"
import { notifyError, notifySuccess } from 'views/MyComponents/Notify/index'
import url from 'views/url';

const httpClient = axios.create({
	baseURL: `${url}`,
});
  

httpClient.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
httpClient.defaults.headers.post['Content-Type'] = 'application/json';
httpClient.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
const cookie = new Cookies();


httpClient.interceptors.request.use(
  config => {
  
    let token =  cookie.get("access_token");
    
    if(token) {
      config.headers = Object.assign(config.headers, {"Authorization": "Bearer " + token});
      return config;
    } else{
        return config
    }
  },
  error => Promise.reject(error)
);

httpClient.interceptors.response.use(response => {
    return response;
}, error => {
  const navigate = useNavigate();

    const status = parseInt(error.response.status)
    if(status === 422){
      notifyError(error.response.data.message)
    }

    if(status === 401){
      notifyError(error.response.data.message)
      cookie.remove("access_token",{path: '/'})
      navigate('/users')

    }

    if(status === 403){
      notifyError(error.response.data.message)
      cookie.remove("access_token")
    }

    if(status === 413){
      notifyError(error.response.data.message)
    }

    if(!status){
      notifyError(error)
    }

    return Promise.reject(error);
});

export const httpGet = (params) => httpClient.request({
	method: 'get',
	...params
});

export const httpPost = (params) => httpClient.request({
  method: 'post',
  ...params
})

export const httpPut = (params) => httpClient.request({
  method: 'put',
  ...params
})

export const httpDelete = (params) => httpClient({
  method: 'delete',
  ...params
});


export const deleteSlider = (id) => httpDelete({
  url: `/panel/sliders/${id}`,
});