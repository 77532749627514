/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { httpGet } from '../../../api';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { EditBtn } from '../svg';

import { Link, useNavigate } from 'react-router-dom';

export default function Users() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const GetNews = () => {
        httpGet({
            url: '/panel/users'
        })
            .then((res) => {
                setUsers(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(async () => {
        await GetNews();
        const token = cookies.get('access_token');

        if (!token) {
            await navigate('/pages/login/login3');
        }
    }, []);
    return (
        <>
            <div className="back">
                <div className="addToBtn">
                    <Typography variant="h4"></Typography>
                    <Link to="/users/add">
                        <Button variant="outlined">Добавить</Button>
                    </Link>
                </div>
            </div>

            <TableContainer component={Paper}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: 'center' }}>ID</TableCell>

                            <TableCell style={{ textAlign: 'center' }} align="right">
                                Наименование
                            </TableCell>

                            <TableCell style={{ textAlign: 'center' }} align="right">
                                Номер телефона
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }} align="right">
                                Дата регистрации
                            </TableCell>

                            <TableCell style={{ textAlign: 'center' }} align="right">
                                Статус
                            </TableCell>

                            <TableCell style={{ textAlign: 'center' }} align="center">
                                Действие
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.length &&
                            users.map((item) => (
                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell style={{ textAlign: 'center' }} component="th" scope="row">
                                        {item.id}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }} component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }} component="th" scope="row">
                                        {item.phone}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }} component="th" scope="row">
                                        {moment(item.created_at).locale('uz-latn').format('DD.MM.yyyy')}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }}
                                        align="center"
                                    >
                                        {item.status === 1 ? (
                                            <Alert
                                                style={{
                                                    color: '#000',
                                                    width: '150px',
                                                    borderColor: '#28B463',
                                                    justifyContent: 'center'
                                                }}
                                                severity="success"
                                                variant="outlined"
                                            >
                                                Активный
                                            </Alert>
                                        ) : (
                                            <Alert style={{ width: '150px', borderColor: '#E74C3C' }} variant="outlined" severity="error">
                                                Неактивный
                                            </Alert>
                                        )}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }} align="right">
                                        <Link
                                            to={{
                                                pathname: `/users/${item.id}`
                                            }}
                                        >
                                            <div className="btttn">
                                                <EditBtn />
                                            </div>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
