/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import avenue from '../../../assets/images/avenue.png'

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase style={{display: 'flex', justifyContent: "center"}} disableRipple component={Link} to={config.defaultPath}>
      <img src={avenue} />
    </ButtonBase>
);

export default LogoSection;
